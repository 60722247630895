const decodePayload = (token) => {
    if (!token) return null
    try {
        const [,encodedPayload] = token.split('.')
        const payload = Buffer.from(encodedPayload, 'base64').toString('utf-8')
        return JSON.parse(payload)
    } catch (err) {
        console.log(err)
        return null
    }
}

export default decodePayload