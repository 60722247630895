const readFromStorage = (key, defaultValue) => {
    const item = window.sessionStorage.getItem(key)
        return !!item
            ? JSON.parse(item)
            : defaultValue
}

const writeInStorage = (key, value) => {
    window.sessionStorage.setItem(key, JSON.stringify(value))
}

export {
    readFromStorage,
    writeInStorage,
}