import { post } from '@api/apiFetcher'
import MessageHelper from '@utils/messageHelper'
import mutateUser from '@utils/user/mutateUser'
import areDifferent from '@utils/object/areDifferentObjects'

const API = '/api/user/peek'
const GLOBAL_KEY = 'wait-for-user-change-and-mutate'

const waitForChangeAndMutate = ({
    peekEndpoint = API,
    auth,
    query,
    compareTo,
    beginMessage,
    successMessage,
    delay = 5000,
}) => {

    if (typeof window === 'undefined') return
    if (beginMessage) {
        MessageHelper.message(beginMessage)
    }

    function setPeekTimeout (func) {
        window[GLOBAL_KEY] = setTimeout(func, delay)
    }

    function peekUser () {
        post({ path: peekEndpoint, data: { query }, auth })
            .then(res => {
                try {
                    if (res.success && res.user) {
                        if (areDifferent(res.user, compareTo)) {
                            const json = localStorage.getItem('user')
                            const user = JSON.parse(json)
                            mutateUser(user, res.user)
                            if (successMessage) {
                               MessageHelper.message(successMessage)
                            }
                        }

                    } else {
                        setPeekTimeout(peekUser)
                    }

                } catch (err) {
                    console.log(err)
                }
            })
    }

    peekUser()
}

export default waitForChangeAndMutate