import { mutate } from 'swr'
import { post } from '@api/apiFetcher'
import mutateUser from '@utils/user/mutateUser'

const SESSION_TIMEOUT_KEY = 'session_timeout'
const SAFETY_MARGIN = 5 * 60 * 1000

const clearSchedule = () => {
    clearTimeout(global[SESSION_TIMEOUT_KEY])
    global[SESSION_TIMEOUT_KEY] = null
}

const setSchedule = (func, time) => {
    clearSchedule()
    global[SESSION_TIMEOUT_KEY] = setTimeout(func, time)
}

const destroySession = () => {
    clearSchedule()
    window.localStorage.removeItem('user')
    mutate('user', null)
}

const scheduleDestroySession = (tokenPayload) => {
    const time = tokenPayload.expires - Date.now()
    setSchedule(destroySession, time)
}

const renewSession = (user) => {
    post({ path: '/api/user/refresh_token', auth: user?.token })
        .then(res => {
            if (res.success) {
                mutateUser(user, res.user) // <- other than mutate from swr imported above
            } else {
                destroySession()
            }
        })
        .catch(err => null)
}

const scheduleRenewSession = (user, expiryTime) => {
    if (global[SESSION_TIMEOUT_KEY] == null) {
        const time = expiryTime - Date.now() - SAFETY_MARGIN
        setSchedule(() => renewSession(user), time)
    }
}

export {
    SESSION_TIMEOUT_KEY,
    destroySession,
    scheduleDestroySession,
    renewSession,
    scheduleRenewSession,
}